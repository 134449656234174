<!--Pagina de la justificación-->
<template>
  <v-container
    fill-height
    fluid
    class="black d-flex align-stretch justify-center"
  >
    <v-row class="" justify="center" no-gutters>
      <v-col cols="12" md="11" class="py-0 px-0">
        <v-toolbar elevation="0" class="menugrande black">
          <img
            :src="require('../assets/beckslogo3.png')"
            width="150px"
            alt="Logo Cerveza Beck´s"
            title="Logo Cerveza Beck´s"
            class="ml-15"
          />
          
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-row justify="center" class="my-0 px-0 py-0" no-gutters>
          <v-col cols="12" md="6" class="px-0 py-0"
            ><!-- Contenido de la izquierda-->
            <v-responsive :aspect-ratio="1" align="center">
              <!--imagen si es escritorio-->
              <v-img
                :aspect-ratio="1"
                width="100%"
                min-width="200"
                class="d-none d-md-flex align-center justify-start"
                :src="require('../assets/becksjustificacionweb.jpg')"
              >
              </v-img>
              <!--imagen si es mobile-->
              <v-img
                :aspect-ratio="1"
                width="90%"
                class="mx-0 d-md-none"
                :src="require('../assets/becksjustificacionmobile.jpg')"
              >
              </v-img>
            </v-responsive>
          </v-col>
          <v-col cols="12" md="5" class="text-center justify-center d-flex"
            ><!-- Contenido de la derecha-->
            <v-card class="black" max-width="500px">

              <h1 class="white--text txtr">
                <span class="bordeado">#</span>UNLOCK
              </h1>
              <h2 class="white--text txtr">
                <span class="bordeado">YOUR</span>TRUE<span class="bordeado"
                  >SELF</span
                >
              </h2>
              <h3 class="becks--text mt-8" style="font-size: 0.83em;">
                ¿ES POSIBLE LANZAR UNA CAMPAÑA PUBLICITARIA CON PERSONAS REALES?
              </h3>

              <h3 class="becks--text mt-8" style="font-size: 0.83em;">
                S&Iacute;. ES M&Aacute;S: NOS GUSTAR&Iacute;A VER M&Aacute;S
                CAMPAÑAS AS&Iacute;, CON M&Aacute;S DIVERSIDAD DE CARNE Y HUESO,
                Y MENOS DE ALGORITMO
              </h3>

              <p class="white--text mt-8" style="font-size: 0.83em; font-weight: bold;">
                CONSTRUIMOS ESTE SITIO PARA QUE SEAS T&Uacute; QUIEN CREA
                NUESTRA CAMPAÑA. TE INVITAMOS A SUBIR TU FOTO Y MOSTRAR TU
                VERDADERO YO, CUALQUIERA QUE &Eacute;STE SEA. EXPERIMENTA,
                JUEGA, SUBE ESA SELFIE QUE TE ENCANTA O ESA QUE MUESTRA UN LADO
                TUYO QUE NO LE HAB&Iacute;AS MOSTRADO A NADIE.
              </p>

              <h3 class="becks--text mt-10 inclinado" style="font-size: 0.83em;">
                USA EL SENTIDO COM&Uacute;N. DIVI&Eacute;RTETE.
              </h3>
              <h3 class="becks--text mb-10 inclinado" style="font-size: 0.83em;">
                T&Uacute; ERES LA CARA DE BECK’S.
              </h3>
              <v-btn
                color="becks"
                large
                elevation="2"
                class="ma-5"
                min-width="300px"
                rounded
                @click="cambiarP('IniciarSesion')"
                onclick="eventoBecks('Continuar-Home','Clic')"
              >
                CONTINUAR
              </v-btn>
              <!--Inicio de las redes sociales-->
              <p class="my-10">
                <a
                  href="https://www.facebook.com/becksmexico"
                  style="text-decoration: none"
                  target="_blank"
                  title="Logo Facebook"
                >
                  <v-btn icon color="becks" class="mx-1">
                    <v-icon>mdi-facebook</v-icon>
                  </v-btn>
                </a>
                <a
                  href="https://twitter.com/Becks_Mx"
                  style="text-decoration: none"
                  target="_blank"
                  title="Logo Twitter"
                >
                  <v-btn icon color="becks" class="mx-1">
                    <v-icon>mdi-twitter</v-icon>
                  </v-btn>
                </a>
                <a
                  href="https://www.instagram.com/becksmexico/"
                  style="text-decoration: none"
                  target="_blank"
                  title="Logo Instagram"
                >
                  <v-btn icon color="becks" class="mx-1">
                    <v-icon>mdi-instagram</v-icon>
                  </v-btn>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCSD0VXsNGfldVvU_dQQgMJQ"
                  style="text-decoration: none"
                  target="_blank"
                  title="Logo Youtube"
                >
                  <v-btn icon color="becks" class="mx-1">
                    <v-icon>mdi-youtube</v-icon>
                  </v-btn>
                </a>
              </p>
              <!--Se comentaron los botones de Terminos y condiciones de la version anterior
              <v-btn color="white" small text tile>
                <Legal />
              </v-btn>
              <v-btn color="white" small text tile>
                <Aviso />
              </v-btn>
              -->
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import menus from "../components/menus.vue";
import axios from "axios";
import Aviso from "../components/aviso.vue";
import Legal from "../components/legal.vue";

export default {
  name: "Justificacion",
  data: () => ({
    anuncioLegal: false
  }),
  methods: {
    cambiarP(lugar) {// Función para cambiar de pagina 
      axios
        .get("/")
        .then((response) => this.$router.push({ name: lugar }))
        .catch((error) => console.log("Hubo un error: " + error));
    },
    deleteCookie(){
      axios
        .post("delete_cookie.php", {
          
        }).then(function (response){
          // console.log(response)
        }).catch((error) => {
          // console.log(error)
        })
    }
  },
  created(){// Al abrir la pagina se borra la cookie del inicio de session 
    this.deleteCookie();
  },
  components: {
    menus,
    Aviso,
    Legal,
  },
};
</script>
<style scoped>
.rotado {
  transform: rotate(45deg);
}
.inclinado {
  transform: rotate(-5deg);
}
.flexcard {
  display: flex;
  flex-direction: column;
}
.opaco {
  opacity: 0.3;
}
.bordeado {
  color: transparent;
  border: 3px;
  -moz-text-stroke-width: 0.1px;
  -webkit-text-stroke-width: 0.1px;
  -moz-text-stroke-color: #ffffff;
  -webkit-text-stroke-color: #ffffff;
}
.txtr {
  font-size: 32pt;
}
@media (max-width: 960px) {
  .bordeado {
    color: transparent;
    border: 3px;
    -moz-text-stroke-width: 1px;
    -webkit-text-stroke-width: 1px;
    -moz-text-stroke-color: #fff;
    -webkit-text-stroke-color: #fff;
  }
  .txtr {
    font-size: 20pt;
  }
}

.block.block-abi-legal-d8{
  width: 100%;
}

.legal_accordion {
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: center;
  outline: none;
  font-size: 15px;
  font-weight: bold;
  transition: 0.4s;
}

.legal_panel {
  overflow: hidden;
  font-size: 13px;

}
</style>